import React, { Component } from 'react';
import { saveRsvp, getUser, saveSong, getSongs } from '../utils/services';
import classnames from 'classnames';

class Rsvp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            init: 1,
            rsvp: 0,
            confirm: -1,
            inProgress: false,
            songs: [],
            delSongIds: []
        };

        this.addGuest = this.addGuest.bind(this);
        this.removeGuest = this.removeGuest.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleSongChange = this.handleSongChange.bind(this);
        this.sendSongs = this.sendSongs.bind(this);
    }

    componentDidMount() {
        getUser().then(users => {
            users.forEach(user => {
                this.setState({
                    rsvp: Math.abs(user.rsvp),
                    confirm: user.confirm
                });
            });
        });
        getSongs()
            .then(songs => {
                this.setState(
                    {
                        songs
                    },
                    () => {
                        if (this.state.songs.length < 3) {
                            this.addHint();
                        }
                    }
                );
            })
            .catch(err => {
                console.error(err);
            });
    }

    addGuest() {
        this.setState({ inProgress: true });
        this.setState(
            prev => ({
                rsvp: prev.rsvp + 1
            }),
            () => {
                this.saveRsvp();
            }
        );
    }

    removeGuest(event) {
        this.setState({ inProgress: true });
        this.setState(
            prev => ({
                rsvp: -1 + prev.rsvp
            }),
            () => this.saveRsvp()
        );
    }

    confirm(event) {
        const confirm = parseInt(event.target.getAttribute('data-confirm'), 10);
        this.setState({ inProgress: true });
        this.setState(
            prev => ({
                confirm
            }),
            () => this.saveRsvp()
        );
        event.preventDefault();
    }

    saveRsvp() {
        const { confirm, rsvp } = this.state;

        saveRsvp({ confirm, rsvp })
            .catch(ex => {
                console.error(ex);
            })
            .finally(() => this.setState({ inProgress: false }));
    }

    sendSongs(event) {
        event.preventDefault();
        const { songs, delSongIds } = this.state;

        const airFilter = songs.filter(song => {
            const results = ['title', 'artist'].every(key => {
                return song[key].length > 0;
            });
            return results > 0;
        });

        if (!airFilter.length && !delSongIds.length) {
            return;
        }

        this.setState({ inProgress: true, init: 0 });

        const removable = delSongIds.map(id => {
            return {
                id
            };
        });

        saveSong(songs.concat(removable))
            .then(songs => {
                this.setState(
                    {
                        songs,
                        delSongIds: []
                    },
                    () => {
                        if (this.state.songs.length - delSongIds.length < 3) {
                            this.addHint();
                        }
                    }
                );
            })
            .catch(ex => {
                console.error(ex);
            })
            .finally(() => this.setState({ inProgress: false }));
    }

    addHint() {
        const { songs } = this.state;
        songs.push({
            title: '',
            artist: ''
        });

        this.setState({
            songs
        });
    }

    handleSongChange(event) {
        const { songs, delSongIds } = this.state;
        const idx = event.target.getAttribute('data-idx');
        const currentSong = songs[idx];

        const value = event.target.value;
        currentSong[event.target.id] = value;

        if (!value && ['artist', 'title'].every(key => currentSong[key].length === 0)) {
            delSongIds.push(currentSong.id);
            songs.splice(idx, 1);
        } else {
            songs.splice(idx, 1, currentSong);
        }

        this.setState({
            songs,
            delSongIds
        });
    }

    renderSongRows() {
        const list = this.state.songs.map((song, idx) => (
            <div className="row" key={`song-${idx}`}>
                <div className="one columns">
                    <div className="ordinar">{1 + idx}</div>
                </div>
                <div className="eleven columns">
                    <div className="six columns">
                        <label htmlFor="title">Title</label>
                        <input
                            className="u-full-width"
                            id="title"
                            type="text"
                            defaultValue={song.title}
                            onChange={this.handleSongChange}
                            data-idx={idx}
                        />
                    </div>
                    <div className="six columns">
                        <label htmlFor="artist">By</label>
                        <input
                            className="u-full-width"
                            id="artist"
                            type="text"
                            defaultValue={song.artist}
                            onChange={this.handleSongChange}
                            data-idx={idx}
                        />
                    </div>
                </div>
            </div>
        ));

        return <div>{list}</div>;
    }

    render() {
        return (
            <div>
                <h2>Are you joining us on Saturday, September 15th?</h2>
                <hr />
                <form>
                    <div className="row breath">
                        <div className="columns center">
                            <input
                                type="submit"
                                value="Yes, wouldn't miss it"
                                disabled={this.state.inProgress || this.state.confirm === 1}
                                className="invite-confirm positive"
                                onClick={this.confirm}
                                data-confirm={1}
                            />
                            <input
                                type="submit"
                                value="No, wish I could"
                                disabled={this.state.inProgress || this.state.confirm === 0}
                                className="invite-confirm negative"
                                onClick={this.confirm}
                                data-confirm={0}
                            />
                            {this.state.inProgress ? <div className="loader">Loading...</div> : ''}
                        </div>
                    </div>
                    <div className={classnames({ nodisplay: this.state.confirm === 0 })}>
                        <p className="center">
                            And how many of your guests will be joining us? Let us know.
                        </p>
                        <div className="container rsvp-cont">
                            <button
                                className="invite positive"
                                type="button"
                                onClick={this.addGuest}
                                disabled={this.state.rsvp > 1}
                            >
                                +
                            </button>
                            <input
                                type="text"
                                disabled={this.state.inProgress}
                                id="rsvp"
                                value={this.state.rsvp}
                                className="invite value"
                            />
                            <button
                                className="invite negative"
                                type="button"
                                onClick={this.removeGuest}
                                disabled={this.state.rsvp < 1}
                            >
                                &#8211;
                            </button>
                        </div>
                    </div>
                </form>
                <h4>By the way, we love music!</h4>
                <p>
                    So regardless of your appeararnce we would love, if you send us a song that you
                    find perfect for this occations.
                </p>
                <form onSubmit={this.sendSongs}>
                    {this.renderSongRows()}
                    <div className="row">
                        <div className="columns right">
                            <span
                                className={classnames('feedback', {
                                    'after-progress': !this.state.inProgress && !this.state.init
                                })}
                            >
                                Thank you!
                            </span>
                            <input
                                type="submit"
                                value="Send"
                                className={classnames({ 'in-progress': this.state.inProgress })}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Rsvp;
