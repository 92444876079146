import React from 'react';

const UserContext = React.createContext({
    role: 'anyone',
    changeRole: role => {
        this.role = role;
    }
});

export default UserContext;
