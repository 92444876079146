import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import Loading from './Loading';
import { getImages, getImageById } from '../utils/services';

class GalleryPreload extends Component {
  constructor(props) {
    super();
    this.state = {
      currentIndex: props.currentIndex,
      errors: props.errors,
      id: props.match.params.id,
      idx: props.match.params.idx,
      images: props.images,
      prevIndex: props.prevIndex,
    };
  }

  componentDidMount() {
    let idx = this.props.match.params.idx;
    return getImages(this.state.id).then(images => {
      if (!images.length) {
        images.errors = [new Error('Could not load images')]
      }
      if (!idx && images.length) {
        idx = images[0].id;
      }
      if (Array.isArray(images)) {
        return this.setState({ images }, () => {
          this.props.setParentState({images})

          return getImageById(this.state.id, idx, this.state.images.length - 1)
            .then(resolvedImages => {
              const { images, currentIndex } = this.state;
              const [currentImage, oneImage, twoImage] = resolvedImages.sort((a, b) => a["index"] - b["index"])

              images[currentImage.index] = currentImage;
              images[oneImage.index] = oneImage;
              images[twoImage.index] = twoImage;

              this.props.setParentState({
                currentIndex: currentImage.index,
              })

              const requstedIdMatch = this.props.location.search.match("=.*$")
              const requestedId = requstedIdMatch && requstedIdMatch[0].substring(1)

              this.props.history.push(`/pictures/${this.state.id}/${requestedId || currentImage.id}`);
            })
            .catch(e => console.log(e));
        });
      }
      const { errors } = images;
      if (errors.length) {
        this.props.setParentState({
          errors,
        }, () => this.state.errors.map(error => console.log(error.message)))
      }
    });
  }


  render() {
    const { images } = this.state;
    if (!images.length) {
      return <h6>No images found</h6>;
    }

    return <Loading width={50} height={50} />;;
  }
}

export default GalleryPreload;
