import React, { Component } from 'react';
import { ArticlesContext } from '../contexts';
import moment from 'moment';

class Articles extends Component {
  renderArticles(articles) {
    return articles.filter(article => article.topone === 0).map(article => (
        <div className="row list" key={article.id}>
        <time
      title={article.title}
      data-update={moment(article.timestamp).format('MMM Do, YY')}
      data-linkto={`/articles/${article.id}`}
        />
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </div>
    ));
  }

  render() {
    return (
      <ArticlesContext.Consumer>
        {({ articles }) => this.renderArticles(articles)}
      </ArticlesContext.Consumer>
    );
  }
}

export default Articles;
