import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ArticleEdit from './ArticleEdit';
import AdminList from './AdminList';
import { isAdmin } from '../utils/services';

const Admin = () => (
    <div>
        <h2>Admin</h2>
        <hr />
        <Switch>
            <AdminRoute exact path="/garden" component={AdminList} />
            <AdminRoute path="/garden/articles/:id" component={ArticleEdit} />
        </Switch>
    </div>
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAdmin() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
        }
    />
);

export default Admin;
