import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ArticleList from './ArticleList';
import Articles from './Articles';
import Rsvp from './Rsvp';
import Pictures from './Pictures';
import Admin from './Admin';
import Enter from './Enter';
import Logout from './Logout';
import { isAuthenticated } from '../utils/services';

const Main = () => (
  <main className="container">
    <Switch>
      <Route exact path="/" component={ArticleList} />
      <Route path="/articles" component={Articles} />
      <Route path="/enter" strict={false} component={Enter} />
      <Route exact path="/exit" component={Logout} />
      <PrivateRoute path="/garden" component={Admin} />
      <PrivateRoute path="/rsvp" component={Rsvp} />
      <PrivateRoute path="/pictures" component={Pictures} />
      <Route path="/*" component={Articles} />
    </Switch>
  </main>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
  {...rest}
  render={props =>
          isAuthenticated() ? (
              <Component {...props} />
          ) : (
              <Redirect
            to={{
              pathname: '/enter',
              state: { referrer: props.location.pathname }
            }}
              />
          )
         }
    />
);

export default Main;
