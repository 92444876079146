import React, { Component } from 'react';
import format from '../utils/format';

const zeroTime = new Date('Sep 15, 2018 14:00:00').getTime();

const remainingDays = format.countUp(zeroTime);

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remainingDays: null
        };
    }

    componentDidMount() {
        this.setState({
            remainingDays
        });
    }

    render() {
        return (
            <footer>
                <h5>Married for {this.state.remainingDays} days and counting!</h5>
            </footer>
        );
    }
}

export default Footer;
