import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import Loading from './Loading';
import { Redirect } from 'react-router-dom';
import { getImages, getImageById } from '../utils/services';

class Gallery extends Component {
  constructor(props) {
    super();
    this.state = {
      currentIndex: props.currentIndex,
      errors: [],
      id: props.match.params.id,
      idx: props.match.params.idx,
      images: props.images,
      isBusy: props.isBusy,
      prevIndex: props.prevIndex,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    let idx = this.props.match.params.idx;
    return getImageById(this.state.id, idx, this.state.images.length - 1)
      .then(resolvedImages => {
	const { images, currentIndex } = this.state;
	const [currentImage, oneImage, twoImage] = resolvedImages;
	images[currentImage.index] = currentImage;
	images[oneImage.index] = oneImage;
	images[twoImage.index] = twoImage;

	this.props.setParentState({
	  currentIndex: currentImage.index,
	})

	if (idx !== currentImage.idx) {
	  this._imageGallery && this._imageGallery.slideToIndex(currentImage.index)
	}
      })
      .catch(e => console.log(e));
  }

  componentWillUnmount() {
    this.props.setParentState({
      prevIndex: 0,
      currentIndex: 0,
      images: [],
    })

    window.removeEventListener('keydown', this.handleKeyDown)
  }

  moveForward() {
    this.fetchSlide('forward');
  }

  moveBackward() {
    this.fetchSlide('backward');
  }

  handleKeyDown(event) {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;

    const key = parseInt(event.keyCode || event.which || 0);

    switch (key) {
      case LEFT_ARROW:
	this.moveBackward();
	break;
      case RIGHT_ARROW:
	this.moveForward();
	break;
      default:
	break;
    }
  }

  _onSlide(index) {
    const move = this.props.prevIndex < index ? 'forward' : 'backward';
    getImageById(this.state.id, this.state.images[index].id, this.state.images.length - 1, move)
      .then(resolvedImages => {
	const { images } = this.state;
	const [currentImage, oneImage, twoImage] = resolvedImages;
	images[oneImage.index] = oneImage;
	images[twoImage.index] = twoImage;
	this.props.setParentState({
	  images,
	  currentIndex: currentImage.index,
	  prevIndex: oneImage.index
	})
	this.props.history.push(`/pictures/${this.state.id}/${currentImage.id}`);
      })
      .catch(e => {
	throw Error('Last slide?', e);
      });
  }

  fetchSlide(move) {
    let index = this.state.currentIndex;
    const size = this.state.images.length - 1;
    if (move === 'forward') {
      index = this.state.currentIndex < size ? 1 + this.state.currentIndex : 0;
    }
    if (move === 'backward') {
      index = this.state.currentIndex > 0 ? -1 + this.state.currentIndex : size;
    }

    this.props.setParentState({
      prevIndex: this.state.currentIndex,
      currentIndex: index,
    })
  }

  render() {
    const { images, isBusy } = this.state;
    if (isBusy) {
      return <Loading width={50} height={50} />;
    }

    if (!images || !images.length && !isBusy) {
      return <Redirect to={{ pathname: `/pictures/${this.state.id}`, search: `?idx=${this.props.match.params.idx}` }} />;;
    }

    return (
      <div>
	<h2>Gallery </h2>
	<ImageGallery
	  ref={i => (this._imageGallery = i)}
	  items={images}
	  slideInterval={5000}
	  showThumbnails={false}
	  showIndex={true}
	  lazyLoad={false}
	  defaultImage="/img/gale/wedding.jpg"
	  additionalClass={this.state.id}
	  disableArrowKeys={true}
	  onSlide={this._onSlide.bind(this)}
	/>
      </div>
    );
  }
}

export default Gallery;
