import React, { Component } from 'react';
import { getArticle } from '../utils/services';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

class Article extends Component {
  constructor(props) {
    super();
    this.state = {
      article: {},
      errors: []
    };
  }

  getArticle(id) {
    return getArticle(id).then(article => this.setState({ article }))
      .catch(e => this.setState({ errors: [e] }));
  }

  componentDidMount() {
    this.getArticle(this.props.id)
  }

  render() {
    const { article, errors } = this.state;
    const { id } = this.props;
    if (id !== this.state.article.id) {
      this.getArticle(id)
    }

    if (errors.find(error => error.status > 400)) {
      return (
          <Redirect
        to={{
          pathname: '/enter',
          state: { referrer: this.props.location.pathname }
        }}
          />
      );
    }

    return (
        <div>
        <div key={article.id}>
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
        <time data-update={moment(article.timeUpdate).format('MMM Do, YY')} />
        </div>
        </div>
    );
  }
}

export default Article;
