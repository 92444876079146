import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getArticle, saveArticle } from '../utils/services';

class ArticleEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                title: '',
                content: '',
                restrict: 0,
                topone: 0
            },
            inProgress: false,
            id: props.match.params.id
        };

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        if (this.state.id.toLowerCase() !== 'new') {
            getArticle(this.state.id).then(article => {
                this.setState({
                    article
                });
            });
        }
    }

    handleChange(event) {
        this.setState({
            article: { ...this.state.article, [event.target.id]: event.target.value }
        });
    }

    save(event) {
        this.setState({ inProgress: true });
        const self = this;
        saveArticle(this.state.id.toLowerCase(), this.state.article).then(article => {
            self.setState({ inProgress: false });
            if (self.state.id === 'new' && article) {
                self.setState({ id: article.id });
            }
            if (article) {
                self.setState(article);
            }
        });
        event.preventDefault();
    }

    toggleBool(event) {
        const value = event.target.value === 'false' ? 1 : 0;
        event.target.value = value;
        this.setState({
            article: { ...this.state.article, [event.target.id]: value }
        });
    }

    render() {
        if (this.props.location.pathname === '/garden/articles/new' && this.state.id !== 'new') {
            return <Redirect to={`/garden/articles/${this.state.id}`} />;
        }

        return (
            <form onSubmit={this.save}>
                <div className="row">
                    <div className="columns">
                        <label htmlFor="title">Title</label>
                        <input
                            className="u-full-width"
                            id="title"
                            type="text"
                            value={this.state.article.title}
                            onChange={this.handleChange}
                        />
                        <label htmlFor="content">Text</label>
                        <textarea
                            className="u-full-width editarea"
                            id="content"
                            value={this.state.article.content}
                            onChange={this.handleChange}
                        />
                        <label htmlFor="restrict">
                            <input
                                id="restrict"
                                type="checkbox"
                                value={this.state.article.restrict > 0}
                                checked={this.state.article.restrict > 0}
                                onClick={this.toggleBool.bind(this)}
                            />
                            <span className="label-body">In Garden (private)?</span>
                        </label>

                        <label htmlFor="topone">
                            <input
                                id="topone"
                                type="checkbox"
                                value={this.state.article.topone > 0}
                                checked={this.state.article.topone > 0}
                                onClick={this.toggleBool.bind(this)}
                            />
                            <span className="label-body">Top Level Page (in navgiation)</span>
                        </label>

                        <div className="center">
                            <input type="submit" value="Save" />
                            {this.state.inProgress ? <div className="loader">Loading...</div> : ''}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default ArticleEdit;
