import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ArticleList from './ArticleList';
import Article from './Article';
import { ArticlesContext } from '../contexts';

class Articles extends Component {
  buildSlugRoutes(articles) {
    return articles.filter((article) => article.slug).map((article) => {
      return (
          <Route exact path={`/${article.slug}`} key={`route-articles-{article.id}`} render={(props) =>  <Article id={article.id} /> } />)
    }).concat([
        <Route exact path="/articles" component={ArticleList} key="route-articles" />,
        <Route path="/articles/:id" render={(props) => <Article id={props.match.params.id} /> } key="route-articles-id" />
    ])
  }

  renderArticles(articles) {
    let routes = this.buildSlugRoutes(articles)
    return (
      <div>
        <Switch>
        {routes}
        </Switch>
      </div>
    )
  }

  render() {
    return (
      <ArticlesContext.Consumer>
        {({ articles }) => this.renderArticles(articles)}
      </ArticlesContext.Consumer>
    );
  }
}

export default Articles;
