import React from 'react';
import { UserContext, ArticlesContext } from '../contexts';
import { isAuthenticated, getArticles } from '../utils/services';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.changeUserRole = role => {
            this.setState(state => ({
                userContext: {
                    user: {
                        role
                    },
                    changeUserRole: this.changeUserRole
                }
            }));
        };

        this.getArticles = () => {
            return getArticles().then((articles = []) => {
                this.setState(state => ({
                    articlesContext: {
                        articles,
                        getArticles: this.getArticles
                    }
                }));
            });
        };

        this.state = {
            userContext: {
                user: {
                    role: isAuthenticated() ? 'guest' : 'anyone'
                },
                changeUserRole: this.changeUserRole
            },
            articlesContext: {
                articles: [],
                getArticles: this.getArticles
            }
        };
    }

    render() {
        const { children } = this.props;
        return (
            <UserContext.Provider value={this.state.userContext}>
                <ArticlesContext.Provider value={this.state.articlesContext}>
                    <div className="app">{children}</div>
                </ArticlesContext.Provider>
            </UserContext.Provider>
        );
    }
}

export default Page;
