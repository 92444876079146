import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import { autoAuthenticate } from '../utils/services';
import { Loading } from '.';

class AutoLogin extends React.Component {
    constructor(props) {
        super(props);
        const { next: referrer = '/' } = qs.parse(this.props.location.search);
        this.state = {
            isBusy: true,
            referrer
        };
    }

    componentDidMount() {
        autoAuthenticate(this.props.match.params.autoToken)
            .then(user => {
                const [changeUserRole, getArticles] = this.props.postSuccessLogin;
                changeUserRole(user.role);
                getArticles();
                this.setState({ isBusy: false });
            })
            .catch(e => this.setState(state => ({ isBusy: false })));
    }

    render() {
        const { isBusy, referrer } = this.state;
        if (isBusy) {
            return <Loading width={50} height={50} />;
        }
        return <Redirect to={referrer} />;
    }
}

export default AutoLogin;
