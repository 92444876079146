import { jwtDecode } from 'jwt-decode';
import httpError from 'http-errors';

import { SEC_PATH } from '../constants';
const ACCESS_TOKEN = 'access_token';

const getArticles = () => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`/api/articles`, {
        method: 'GET',
        headers
    })
        .then(response => response.json())
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const getArticle = id => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return fetch(`/api/articles/${id}`, {
        method: 'GET',
        headers
    })
        .then(response => {
            if (response.status > 400) {
                throw httpError(response.status);
            }
            return response.json();
        })
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
            }
            throw error;
        });
};

const saveArticle = (id, article) => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    let ident = `articles/${id}`;
    let method = 'PUT';
    if (id === 'new') {
        ident = 'articles';
        method = 'POST';
    }

    return fetch(`/api/${SEC_PATH}/${ident}`, {
        body: JSON.stringify(article),
        method: method,
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const getUser = params => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const decoded = jwtDecode(token);
    return fetch(`/api/users/${decoded.id}`, params).then(response => response.json());
};

const getUsers = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    return fetch(`/api/${SEC_PATH}/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const getSongs = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const decoded = jwtDecode(token);

    return fetch(`/api/${SEC_PATH}/songs?userId=${decoded.id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return [];
        })
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const saveRsvp = rsvp => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const decoded = jwtDecode(token);

    return fetch(`/api/${SEC_PATH}/users/${decoded.id}`, {
        body: JSON.stringify(rsvp),
        method: 'PUT',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        return res.text();
    });
};

const saveSong = songs => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const decoded = jwtDecode(token);
    const payload = songs.map(song => {
        song.userId = decoded.id;
        delete song.timeCreate;
        delete song.timeUpdate;
        return song;
    });

    return fetch(`/api/${SEC_PATH}/songs`, {
        body: JSON.stringify(payload),
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const getGalleries = () => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`/api/gallery`, {
        method: 'GET',
        headers
    })
        .then(response => response.json())
        .catch(error => {
            if (error.status === 401) {
                deAuthenticate();
                window.location = '/enter';
                return undefined;
            }
            return [];
        });
};

const getImages = id => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`/api/gallery/${id}`, {
        method: 'GET',
        headers
    })
        .then(response => response.json())
        .catch(error => {
            if (error.status > 401) {
                deAuthenticate();
                window.location = '/enter';
                return undefined;
            }
            return [];
        });
};

const getImageById = (id, idx, size, move) => {
    const headers = {
        'Content-Type': 'application/json'
    };
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    let uri = `/api/gallery/${id}/${idx}?size=${size}`;
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    if (move) {
        uri += `&move=${move}`;
    }

    return fetch(uri, {
        method: 'GET',
        headers
    })
        .then(response => response.json())
        .catch(error => {
            if (error.status > 400) {
                deAuthenticate();
                window.location = '/enter';
            }
        });
};

const isAuthenticated = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    return !!token && !isTokenExpired(token);
};

const isAdmin = () => {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    return isAuthenticated() && jwtDecode(token).role === 'admin';
};

const deAuthenticate = () => {
    window.localStorage.removeItem(ACCESS_TOKEN);
};

const authenticate = (email, password) => {
    return fetch(`/api/enter`, {
        body: JSON.stringify({
            email,
            password
        }),
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
        .then(res => {
            if (!res.ok) {
                throw res.statusText;
            }
            return res.json();
        })
        .then(resp => {
            if (resp.token) {
                window.localStorage.setItem(ACCESS_TOKEN, resp.token);
                return jwtDecode(resp.token);
            }
            throw new Error('Authentication token error');
        })
        .catch(err => {
            throw err;
        });
};

const autoAuthenticate = autoToken => {
    return fetch(`/api/enter/${autoToken}`)
        .then(res => {
            if (!res.ok) {
                throw res.statusText;
            }
            return res.json();
        })
        .then(resp => {
            if (resp.token) {
                window.localStorage.setItem(ACCESS_TOKEN, resp.token);
                return jwtDecode(resp.token);
            }
            throw new Error('Authentication token error');
        })
        .catch(err => {
            throw err;
        });
};

const isTokenExpired = token => {
    const decoded = jwtDecode(token);
    const date = new Date(0);

    if (!decoded.exp) {
        return null;
    }

    date.setUTCSeconds(token.exp);
    return date < new Date();
};

export {
    authenticate,
    autoAuthenticate,
    deAuthenticate,
    getArticle,
    getArticles,
    getGalleries,
    getImages,
    getImageById,
    getSongs,
    getUser,
    getUsers,
    isAdmin,
    isAuthenticated,
    saveArticle,
    saveRsvp,
    saveSong
};
