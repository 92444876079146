import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { getGalleries } from '../utils/services';
import { Gallery, GalleryPreload, Loading } from '.';

class Pictures extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentIndex: 0,
          errors: [],
          galleries: [],
          images: [],
          isBusy: true,
          prevIndex: 0,
        };
    }

    componentDidMount() {
      getGalleries()
        .then(galleries => {
          if (Array.isArray(galleries)) {
            return this.setState({
              galleries,
              isBusy: false
            });
          }
          const { errors } = galleries;
          if (errors.length) {
            return this.setState(
              {
                errors,
                isBusy: false
              },
              () => this.state.errors.map(error => console.log(error.message))
            );
          }
        })
        .catch(error => console.log('CLIENT>GET>GALLERIES>ERROR: ', error));
    }

  renderItem(isIndex, idx, gallery) {
    if (isIndex) {
      return (
          [<li key={`gallery-${idx}`}>
            <h3>
              <Link to={`/pictures${gallery.path_lower}`}>
                <img
                  className="intro-image"
                  src={gallery.introImage}
                  alt={gallery.displayName}
                />
                {gallery.displayName}
              </Link>
            </h3>
          </li>,
          <li key={`gallery-caption-${idx}`}>
            <h5>{gallery.caption}</h5>
          </li>
        ]
      );
    }

    return (
      <li key={`gallery-${idx}`}>
        <h5>
          <Link to={`/pictures${gallery.path_lower}`} className={
            String(this.props.location.pathname).match("\/+.*\/")[0].slice(0,-1).match(gallery.path_lower) ? "selected" : ""
            }>{gallery.displayName}</Link>
          </h5>
      </li>
    );
  }

  setParentState(obj) {
    this.setState({...obj})
  }

  render() {
    const isIndex = /\/pictures$/.test(this.props.location.pathname);
    const { galleries, isBusy, images } = this.state;
    if (isBusy) {
      return <Loading width={50} height={50} />;
    }

    if (!galleries.length && !!isBusy) {
        return <h6>No galleries found</h6>;
    }

    return (
      <div className="pictures">
        <Switch>
          <Route path="/pictures/:id/:idx" render={(routeProps) => <Gallery {...routeProps} {...this.state} setParentState={this.setParentState.bind(this)} /> } />
          <Route path="/pictures/:id" render={(routeProps) => <GalleryPreload {...routeProps} {...this.state} setParentState={this.setParentState.bind(this)} /> } />
        </Switch>

        {isIndex ? <h2>Our Galleries</h2> : <h4>Our Galleries</h4>}
        <ul>{galleries.map((gallery, idx) => this.renderItem(isIndex, idx, gallery))}</ul>
      </div>
    );
  }
}

export default Pictures;
