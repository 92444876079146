function countDown(end) {
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    let timer;

    function showRemaining() {
        const now = new Date().getTime();
        var distance = end - now;
        if (distance < 0) {
            clearInterval(timer);

            return '';
        }
        const days = Math.floor(distance / _day);
        // const hours = Math.floor((distance % _day) / _hour);
        // const const = Math.floor((distance % _hour) / _minute);
        // const seconds = Math.floor((distance % _minute) / _second);

        return days;
    }

    timer = setInterval(showRemaining, 10000);
    return showRemaining();
}

function countUp(start) {
    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    let timer;

    function showRemaining() {
        const now = new Date().getTime();
        var distance = now - start;
        if (distance < 0) {
            clearInterval(timer);
            return '';
        }
        const days = Math.floor(distance / _day);
        // const hours = Math.floor((distance % _day) / _hour);
        // const const = Math.floor((distance % _hour) / _minute);
        // const seconds = Math.floor((distance % _minute) / _second);

        return days;
    }

    timer = setInterval(showRemaining, 10000);
    return showRemaining();
}

export default { countDown, countUp };
