import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../utils/services';
import { UserContext, ArticlesContext } from '../contexts';
import config from '../config';
import classnames from 'classnames';

const ROLES_RESTRICT = {
  admin: 1,
  guest: 1,
  anyone: 0
};

class Nav extends Component {
  constructor(props) {
    super(props);
    this.concludeGetArticles = this.concludeGetArticles.bind(this);
    this.getArticles = this.getArticles.bind(this);
  }

  getArticles(getArticlesFromContext) {
    return () => {
      getArticlesFromContext();
    };
  }

  concludeGetArticles() {}

  componentDidMount() {
    this.concludeGetArticles();
  }

  renderArticlesNav(user, articles) {
    return articles
      .filter(article => {
        return article.restrict === ROLES_RESTRICT[user.role] && article.topone > 0;
      })
      .map((article, idx) => {
        const regexp = new RegExp('^/' + article.slug);
        const uri = `/${article.slug}` || `/articles/${article.id}`
        return (
            <li
          key={`nav-item-${idx}`}
          className={regexp.test(this.props.location.pathname) ? 'on' : ''}
            >
            <Link to={uri}>{article.title}</Link>
            </li>
        );
      });
  }

  render() {
    return (
        <UserContext.Consumer>
        {({ user }) => (
            <ArticlesContext.Consumer>
            {({ articles, getArticles }) =>
             (this.concludeGetArticles = this.getArticles(getArticles)) && (
                 <nav>
                 <ul>
                 <li
               className={
                 this.props.location.pathname === '/' ? 'on' : ''
               }
                 >
                 <Link to="/">Welcome</Link>
                 </li>
                 {this.renderArticlesNav(user, articles)}
               {isAuthenticated() && config.displayRsvp ? (
                   <li
                 className={
                   this.props.location.pathname === '/rsvp'
                     ? 'on'
                     : ''
                 }
                   >
                   <Link to="/rsvp">RSVP</Link>
                   </li>
               ) : null}
               {isAuthenticated() && config.displayPictures ? (
                   <li
                 className={
                   this.props.location.pathname.startsWith(
                     '/pictures'
                   )
                     ? 'on'
                     : ''
                 }
                   >
                   <Link to="/pictures">Pictures</Link>
                   </li>
               ) : null}
               {isAdmin() ? (
                   <li
                 className={classnames('bckg-admin', {
                   on: this.props.location.pathname.startsWith(
                     '/garden'
                   )
                 })}
                   >
                   <Link to="/garden">A</Link>
                   </li>
               ) : null}
               </ul>
                 </nav>
             )
            }
          </ArticlesContext.Consumer>
        )}
      </UserContext.Consumer>
    );
  }
}

const EnhancedNav = withRouter(props => <Nav {...props} />);

export default EnhancedNav;
