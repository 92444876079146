import React from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import Page from './Page';

const App = () => (
    <div className="shell">
        <div className="shell-01">
            <div className="shell-02">
                <Page>
                    <Header />
                    <Main />
                    <Footer />
                </Page>
            </div>
        </div>
    </div>
);

export default App;
