import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../contexts';
import { deAuthenticate } from '../utils/services';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.concludeLogout = this.concludeLogout.bind(this);
    }

    logout(changeUserRole) {
        return () => {
            changeUserRole('anyone');
            deAuthenticate();
        };
    }

    concludeLogout() {}

    componentDidMount() {
        this.concludeLogout();
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ changeUserRole }) => {
                    this.concludeLogout = this.logout(changeUserRole);
                    return <Redirect to={{ pathname: '/' }} />;
                }}
            </UserContext.Consumer>
        );
    }
}

export default Logout;
