import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Nav from './Nav';
import { isAuthenticated } from '../utils/services';
import logo from '../img/lelumus-header.jpg';

const Header = props => {
    const isIn = isAuthenticated();
    const onLogin = /\/enter$/.test(window.location);
    return (
        <header>
            <div className="container">
                <Link
                    to={isIn ? '/exit' : '/enter'}
                    className={classnames('button button-primary button-login', { on: onLogin })}
                >
                    {isIn ? 'Log Out' : 'Log In'}
                </Link>
            </div>
            <div className="logo">
                <img src={logo} alt="Loida & Radek" />
            </div>

            <div className="container">
                <div className="row navig">
                    <Nav />
                </div>
            </div>
        </header>
    );
};

export default Header;
