import React, { Component } from 'react';
import classnames from 'classnames';

const initState = {
    inProgress: false,
    feedback: ''
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = initState;
        this.login = this.login.bind(this);
    }

    login(event) {
        this.setState({
            inProgress: true
        });
        this.props.submit(this.email.value, this.password.value).catch(err => {
            this.setState({
                inProgress: false,
                feedback: err
            });
        });

        event.preventDefault();
    }

    componentWillUnmount() {
        this.setState(initState);
    }

    render() {
        return (
            <form onSubmit={this.login} className="login">
                <div className="row">
                    <div className="three columns">&nbsp;</div>
                    <div className="six columns">
                        <label htmlFor="email">Email</label>
                        <input
                            className="u-full-width"
                            id="email"
                            type="email"
                            placeholder="mia@mailbox.com"
                            ref={email => (this.email = email)}
                        />
                        <label htmlFor="password">Password</label>
                        <input
                            className="u-full-width"
                            id="password"
                            type="password"
                            placeholder="***"
                            ref={password => (this.password = password)}
                        />
                        <p className="center">
                            <input
                                type="submit"
                                value="Log In"
                                className={classnames({ 'in-progress': this.state.inProgress })}
                            />
                        </p>
                        <p
                            className={classnames(['center', 'feedback'], {
                                'after-progress': !this.state.inProgress && !this.state.init
                            })}
                        >
                            {this.state.feedback}
                        </p>
                    </div>
                </div>
            </form>
        );
    }
}

export default Login;
