import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { authenticate } from '../utils/services';
import { AutoLogin, Login } from '.';
import { UserContext, ArticlesContext } from '../contexts';

class Enter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            referrer: '/'
        };
        this.login = this.login.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    login(changeUserRole, getArticles) {
        return (user, passwd) => {
            const concludeLogin = this.handleLogin();
            return authenticate(user, passwd).then(resp => {
                changeUserRole(resp.role);
                getArticles();
                return concludeLogin();
            });
        };
    }

    handleLogin() {
        const { location } = this.props;
        const { state = {} } = location;
        const { referrer = '/' } = state;
        return () => this.setState({ redirectToReferrer: true, referrer });
    }

    render() {
        const { redirectToReferrer, referrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={referrer} />;
        }

        return (
            <UserContext.Consumer>
                {({ changeUserRole }) => (
                    <ArticlesContext.Consumer>
                        {({ getArticles }) => (
                            <Switch>
                                <Route
                                    exact
                                    path="/enter"
                                    render={props => (
                                        <Login submit={this.login(changeUserRole, getArticles)} />
                                    )}
                                />
                                <Route
                                    path="/enter/:autoToken"
                                    render={props => (
                                        <AutoLogin
                                            {...props}
                                            postSuccessLogin={[changeUserRole, getArticles]}
                                        />
                                    )}
                                />
                            </Switch>
                        )}
                    </ArticlesContext.Consumer>
                )}
            </UserContext.Consumer>
        );
    }
}

export default Enter;
