import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getArticles, getUsers } from '../utils/services';
import classnames from 'classnames';
import moment from 'moment';

class Articles extends Component {
    constructor() {
        super();
        this.state = {
            articles: [],
            users: []
        };
    }

    getArticles() {
        getArticles().then(articles => {
            this.setState({ articles });
        });
        getUsers().then(users => {
            this.setState({ users });
        });
    }

    componentDidMount() {
        this.getArticles();
    }

    render() {
        const { articles, users } = this.state;

        return (
            <div>
                <h3>Articles</h3>
                <table className="u-full-width">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Modification date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((article, index) => (
                            <tr key={article.id}>
                                <td>{article.id}</td>
                                <td>
                                    <Link to={`/garden/articles/${article.id}`}>
                                        {article.title}
                                    </Link>
                                </td>
                                <td>{moment(article.timeUpdate).format('ddd M/DD/YY @ H:mm a')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Link to="/garden/articles/new" className="button u-pull-right">
                    Create New Post
                </Link>

                <div className="u-cf" />

                <h3>Users</h3>
                <table className="u-full-width">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>RSVP</th>
                            <th>Role</th>
                            <th>Modification date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr
                                key={user.id}
                                className={classnames({ highlight: user.role === 'admin' })}
                            >
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.rsvp}</td>
                                <td>{user.role}</td>
                                <td>{moment(user.timeUpdate).format('ddd M/DD/YY @ H:mm a')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Articles;
