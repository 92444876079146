import React from 'react';
import classnames from 'classnames';

const Loading = ({ classes = ['loading-container'], width = '20px', height = 'auto' }) => (
    <div className={classnames(classes)} style={{ width, height }}>
        <svg className="loading-spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle
                className="loading-spinner-path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
            />
        </svg>
    </div>
);

export default Loading;
